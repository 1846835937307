<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="mb-7">
      <v-data-table :headers="tableColumnHeaders" :items="checkouts" hide-default-footer disable-pagination >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import { getCheckouts } from '@/api/user'
import { mdiDotsVertical } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: {},
  props: {
    userId: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const checkouts = ref([])

    checkouts.value = props.userId
    // const loadCheckouts = async () => {
    //   try {
    //     const {
    //       data: { status, data },
    //     } = await getCheckouts(props.userId)

    //     if (status === 'success') {
    //       checkouts.value = data.records
    //     }
    //   } catch (error) {
    //     console.error(error.message)
    //   }
    // }

    onMounted(async () => {
      // if (props.userId) {
      //   await loadCheckouts()
      // }
    })

    const tableColumnHeaders = [
      { text: 'Category', value: 'category', sortable: false },
      {
        text: 'Key',
        value: 'key',
        sortable: false,
      },
      {
        text: 'Value',
        value: 'value',
        sortable: false,
      },
      {
        text: 'Value Type',
        value: 'value_type',
        sortable: false,
      },
    ]

    return {
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
      },
      checkouts,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
