var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tab-overview"
  }, [_c('v-card', {
    staticClass: "mb-7"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumnHeaders,
      "items": _vm.checkouts,
      "hide-default-footer": "",
      "disable-pagination": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }